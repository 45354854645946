<template>
  <el-main>
    <el-form class="el-form-search" label-width="70px">
      <el-form-item label="名称：">
        <el-input v-model="name" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="250" label="用户信息" align="center">
        <template v-slot="{ row }">
          <div class="user-info">
            <el-image :src="row.avatar ? row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png'"></el-image>
            <span>{{ row.name || '--' }}</span>
            <el-tag size="small" style="margin-left: 5px" type="success" effect="plain">企微好友</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系人类型" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '微信用户' : '企业微信用户' }}
        </template>
      </el-table-column>
      <el-table-column label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.gender == 0 ? '未知' : row.gender == 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column label="用户标签" align="center">
        <template v-slot="{ row }">
          <span style="margin-right: 10px" v-for="(item, index) in row.external_contact_tag" :key="index">{{ item }}</span>
        </template>
      </el-table-column>
      <el-table-column label="加入方式" align="center">
        <template v-slot="{ row }">
          {{ row.external_contact_addway ? row.external_contact_addway[0] : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center" width="160">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="(showSendMessage = !0), (external_userid = row.external_userid)">联系{{row.gender == 1 || row.gender == 0 ? '他' : '她' }}</el-button>
          <el-button type="text" size="small" @click="$router.push({ path: '/user/enterpriseWechat/sendLog', query: { external_userid: row.external_userid } })">发送记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <SendMessage v-if="showSendMessage" :external_userid="external_userid"></SendMessage>
    <Paging class="paging" :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
import { getDateformat } from '../../../util/getDate';
import SendMessage from '../components/sendMessage';
export default {
  components: {
    Paging,
    SendMessage,
  },
  data() {
    return {
      name: '',
      page: 1,
      rows: 10,
      total: 0,
      list: [],
      showResult: !1,
      external_userid: '',
      showSendMessage: !1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.user.wxwork.userList, {
          page: this.page,
          rows: this.rows,
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .user-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    span:nth-child(2) {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>